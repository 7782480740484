import axios from "axios";

interface IHandleServiceErrorProps {
    silentFail?: boolean;
}

const HandleServiceError = ({silentFail = false}: IHandleServiceErrorProps = {}) => {
    return (_target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            try {
                return await originalMethod.apply(this, args);
            } catch (error) {
                // Checks if the error is an AxiosError
                if (!axios.isAxiosError(error)) throw error;

                // Gets the status code from the response or uses 0
                const status = error.response?.status || 0;

                // Gets the logger from the class instance or uses the console
                const logger = (this as any).logger || console;

                // Checks if the error is a timeout
                const isTimeout = error.code === "ECONNABORTED";
                // Checks if the error is a connection reset
                const isConnectionReset = error.code === "ECONNRESET";
                // Checks if the error is offline
                const isOffline = error.message.includes("Network Error") || error.code === "ERR_NETWORK";

                if (!status) {
                    // Handles network errors
                    logger.warn(`${propertyKey} - Network error occurred`, {
                        error: {
                            status,
                            url: error.config?.url,
                            method: error.config?.method,
                            context: args,
                        },
                    });
                    if (isTimeout || isConnectionReset || isOffline) return;
                } else if (status >= 400 && status < 500) {
                    // Handles user errors (4xx)
                    logger.warn(`${propertyKey} - User error occurred`, {
                        error: {
                            status,
                            url: error.config?.url,
                            method: error.config?.method,
                            data: error.response?.data,
                            context: args,
                        },
                    });
                } else if (status >= 500) {
                    // Handles server errors (5xx)
                    logger.error(`${propertyKey} - Server error occurred`, {
                        error: {
                            status,
                            url: error.config?.url,
                            method: error.config?.method,
                            data: error.response?.data,
                            context: args,
                        },
                    });
                } else {
                    // Handles unexpected cases
                    logger.error(`${propertyKey} - An unexpected error occurred`, {
                        error: {
                            status,
                            url: error.config?.url,
                            method: error.config?.method,
                            data: error.response?.data,
                            context: args,
                        },
                    });
                }

                // Fails silently if needed
                if (silentFail) return;

                // Always rethrow the error
                throw error;
            }
        };
        return descriptor;
    };
};

export {HandleServiceError};
