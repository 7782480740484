import {ImplicitFlowService} from "./ImplicitFlowService";
import {AxiosInstance} from "axios";
import {env} from "core/env";
import {HandleServiceError} from "decorators/service";
import qs from "qs";
import type {DeviceGatewaysAttributes, PassCodeAttributes, PassCodePayloadAdd, PassCodePayloadUpdate, PassCodesListAttributes} from "typing/dto";
import type {DataType, GetListResponse, GetResponse, PostAndPatchResponse, SmartHomeFilter} from "typing/request";

export class SmartHomeService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_SMART_HOME_SERVICE_URL;
    private static service: SmartHomeService;

    constructor() {
        super(SmartHomeService.baseUrl);
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): SmartHomeService {
        if (!SmartHomeService.service) {
            SmartHomeService.service = new SmartHomeService();
        }
        return SmartHomeService.service;
    }

    private getHeaders(userId: number) {
        return {
            "Operator-ID": userId,
        };
    }

    /**
     * Get Device Gateways list
     * @param filter
     * @returns
     */
    @HandleServiceError()
    public async getDeviceGateways(filter?: SmartHomeFilter): Promise<DataType<DeviceGatewaysAttributes>[] | null> {
        const axios: AxiosInstance = await this.axiosInstance();
        const params = {
            filter,
        };
        const {data: response}: {data: GetListResponse<DeviceGatewaysAttributes>} = await axios.get(`${this.apiUrl}/device_gateways`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: "brackets"});
            },
        });

        // early return if none
        if (!response?.data) return null;

        return response?.data;
    }

    /**
     * Get PassCodes list
     * @param filter
     * @returns
     */
    @HandleServiceError()
    public async getPassCodes(filter?: SmartHomeFilter): Promise<DataType<PassCodesListAttributes>[] | null> {
        const axios: AxiosInstance = await this.axiosInstance();
        const params = {
            filter,
        };
        const {data: response}: {data: GetListResponse<PassCodesListAttributes>} = await axios.get(`${this.apiUrl}/passcodes`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: "brackets"});
            },
        });

        // early return if none
        if (!response?.data) return null;

        return response?.data;
    }

    /**
     * Get single passCode by id
     * @param userId
     * @param passCodeId
     * @returns
     */
    @HandleServiceError()
    public async getPassCodeById(userId: number, passCodeId: number): Promise<DataType<PassCodeAttributes> | null> {
        const axios: AxiosInstance = await this.axiosInstance();
        const {data: response}: {data: GetResponse<PassCodeAttributes>} = await axios.get(`${this.apiUrl}/passcodes/${passCodeId}`, {
            headers: this.getHeaders(userId),
        });
        return response?.data;
    }

    /**
     * Create passCode
     * @param userId
     * @param payload
     * @returns
     */
    @HandleServiceError()
    public async addPassCode(userId: number, payload: PassCodePayloadAdd): Promise<DataType<PassCodeAttributes>> {
        const axios: AxiosInstance = await this.axiosInstance();
        const request = {
            data: {
                type: "passcodes",
                attributes: payload,
            },
        };
        const response: PostAndPatchResponse<PassCodeAttributes> = await axios.post(`${this.apiUrl}/passcodes`, request, {
            headers: this.getHeaders(userId),
        });
        return response?.data;
    }

    /**
     * Update passCode
     * @param userId
     * @param passCodeId
     * @param payload
     * @returns
     */
    @HandleServiceError()
    public async patchPassCode(userId: number, passCodeId: number, payload: PassCodePayloadUpdate): Promise<DataType<PassCodeAttributes>> {
        const axios: AxiosInstance = await this.axiosInstance();
        const request = {
            data: {
                type: "passcodes",
                attributes: payload,
            },
        };
        const response: PostAndPatchResponse<PassCodeAttributes> = await axios.patch(`${this.apiUrl}/passcodes/${passCodeId}`, request, {
            headers: this.getHeaders(userId),
        });
        return response?.data;
    }

    /**
     * Delete passCode
     * @param userId
     * @param passCodeId
     */
    @HandleServiceError()
    public async removePassCode(userId: number, passCodeId: number): Promise<void> {
        const axios = await this.axiosInstance();
        return await axios.delete(`${this.apiUrl}/passcodes/${passCodeId}`, {
            headers: this.getHeaders(userId),
        });
    }
}
