import { PropsWithChildren, useState } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import styles from './CopyToClipboard.module.scss'
import { enqueueSnackbar } from "notistack";
import { anchorOrigin } from "utils/message";

interface ICopyToClipboardProps extends PropsWithChildren {
    text: string;
}

export const CopyToClipboard: React.FC<ICopyToClipboardProps> = ({ children, text }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const copyTextToClipboard = async (_text: string) => {
        if (!navigator.clipboard) {
            datadogLogs.logger.warn("CopyToClipboard navigator.clipboard not available", {});
            enqueueSnackbar("This functionality is not supported on your browser. Please use a modern browser.", {variant: "error", anchorOrigin});
            return;
        }

        try {
            await navigator.clipboard.writeText(text);
            setShowTooltip(true);

            setTimeout(() => {
                setShowTooltip(false);
            }, 2000);
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.warn("CopyToClipboard failed to copy text", {}, e);
            enqueueSnackbar("Oops, we couldn't copy the text automatically", {variant: "error", anchorOrigin});
        }
    }

    return (
        <div className={styles.content}>
            <div
                data-testid="pointer-box"
                className={styles['pointer-box']}
                onClick={() => copyTextToClipboard(text)}
            >
                {children}
            </div>
            {showTooltip && (
                <div className={styles.tooltip}>
                    ✓ Copied
                </div>
            )}
        </div>
    );
}
