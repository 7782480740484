import React, { useState, useRef } from "react";
import { AssignableUserAttributes } from "typing/dto";
import { enqueueError, getErrorMessage } from "utils/message";
import { TicketingService } from "services/TicketingService";
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";
import { DataType } from "typing/request";
import { EVENT_LOG_DATA } from "appConstants";
import styles from "./MentionsTextarea.module.scss";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

export interface ItemType {
    username: string;
    display: string;
}

export interface IMentionsTextarea {
    placeholder: string
    onChange?: (text: string) => void;
    className?: string;
    value?: string;
    maxLength?: number;
    testId?: string;
}

// services
const ticketingService = TicketingService.getInstance();

export const MentionsTextarea: React.FC<IMentionsTextarea> = ({
    placeholder = "",
    maxLength = 5000,
    onChange,
    className,
    testId = "mentions-textarea",
    value
}) => {
    const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);
    const [currentWord, setCurrentWord] = useState<string>('');
    const [suggestions, setSuggestions] = useState<ItemType[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestionsCoordinates, setSuggestionsCoordinates] = useState<{ left: number, top: number }>({ left: 0, top: 0 });
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const coverBoxRef = useRef<HTMLDivElement | null>(null);

    /**
     * Method to fetch assignable users and return an array mapping username and display for each user.
     * @param search
     */
    const fetchSuggestions = async (search: string) : Promise<ItemType[]> => {
        try {
            const response = await ticketingService.getAssignees({
                search_term: search
            }, {}, {requestCancellation: true});
            return response?.data.map((user: DataType<AssignableUserAttributes>): ItemType => ({
                username: user.attributes.username,
                display: `${user.attributes.first_name} ${user.attributes.last_name}`
            }));
        } catch (error) {
            enqueueError({logInfo: EVENT_LOG_DATA.TICKET_ASSIGNABLE_USER_GET, error: Error(getErrorMessage(error))});
            return [];
        }
    };

    const onTextChange = (value: string) => {
        if (onChange) onChange(value);
    };

    /**
     * Fetch users to populates suggestions lists. This uses debounce feature to avoid request constantly.
     */
    const search = useCallbackWithDebounce(async (searchString: string): Promise<any> => {
        try {
            const suggestionsResponse = await fetchSuggestions(searchString);
            setSuggestions(suggestionsResponse);
            setShowSuggestions(true);
        } finally {
            setLoadingSuggestions(false);
        }
    });

    /**
     * This method get current word in cursor on textarea.
     * If the current word match with mentions regular expression, calculates correct suggestions list coordinates
     * and perform a request to get users to suggest.
     */
    const handleCursorChange = () => {
        const newCurrentWord: string = getCurrentWord();

        // Early return if current word didn't change
        if (currentWord === newCurrentWord) return;

        setShowSuggestions(false);
        setSuggestions([]);

        const match = /@((.+){3,}$)/.exec(newCurrentWord);
        if (match) {
            calculateSuggestionsCoordinates();
            setLoadingSuggestions(true);
            const [, query] = match;
            search(query);
        } else {
            setLoadingSuggestions(false);
        }
        setCurrentWord(newCurrentWord);
    }

    /**
     * Get first position of the current word in the textarea value.
     * For example: if the value of textarea is "this is the text example of this method". If the
     * cursor is on "example" word, then this method will return 17, because that is the position of the start
     * of current word inside whole text.
     */
    const getCurrentWordStart = () => {
        // Early return
        if (!textareaRef.current || !currentWord || !value) return;

        const textarea = textareaRef.current;
        const cursorPosition = textarea.selectionStart;

        const beforeCursor = value.slice(0, cursorPosition);
        const lastSpaceBefore = beforeCursor.lastIndexOf(' ') + 1;
        const lastNewLineBefore = beforeCursor.lastIndexOf('\n') + 1;
        const referencePosition = Math.max(lastSpaceBefore, lastNewLineBefore);
        return referencePosition > 0 ? referencePosition : 0;
    }

    /**
     * Get last position of the current word in the textarea value.
     * For example: if the value of textarea is "this is the text example of this method". If the
     * cursor is on "example" word, then this method will return 24, because that is the position of the end
     * of current word inside whole text.
     */
    const getCurrentWordEnd = () => {
        // Early return
        if (!textareaRef.current || !coverBoxRef.current || !value) return;

        const textarea = textareaRef.current;
        const cursorPosition = textarea.selectionStart;

        const afterCursor = value.slice(cursorPosition);

        const nextSpaceAfter = afterCursor.indexOf(' ');
        const nextNewLineAfter = afterCursor.indexOf('\n');

        const referencePosition = Math.min(
            nextSpaceAfter === -1 ? value.length : nextSpaceAfter,
            nextNewLineAfter === -1 ? value.length : nextNewLineAfter
        );

        return referencePosition > -1 ? cursorPosition + referencePosition : value.length;
    }

    /**
     * Calculate position for suggestion lists.
     * This populates the cover box element with text and introduce a temporarily "span" element. Then extract coordinates
     * from span element to use these for suggestions list position.
     * Finally, removes span element from cover box.
     */
    const calculateSuggestionsCoordinates = () => {
        // Early return
        if (!textareaRef.current || !coverBoxRef.current || !value) return;

        const wordEnd = getCurrentWordEnd();

        const textBeforeCaret = value.substring(0, wordEnd);
        const lastNewlineIndex = textBeforeCaret.lastIndexOf('\n');

        // Create temporarily span element to measure text size until caret position
        const tempSpan = document.createElement('span');
        tempSpan.style.visibility = 'hidden';
        coverBoxRef.current.appendChild(tempSpan);

        tempSpan.innerText = textBeforeCaret;
        let { offsetWidth: horizontalPosition, offsetHeight: verticalPosition } = tempSpan;

        // Check if there are line breaks
        if (lastNewlineIndex !== -1) {
            tempSpan.innerText = value.substring(lastNewlineIndex, wordEnd);
            const { offsetWidth: newHorizontalPosition } = tempSpan;
            horizontalPosition = newHorizontalPosition;
        }
        // Remove temp element
        coverBoxRef.current.removeChild(tempSpan);

        setSuggestionsCoordinates({ left: horizontalPosition, top: verticalPosition });
    }

    /**
     * Get current word in cursor position in textarea.
     */
    const getCurrentWord = (): string => {
        // Early return
        if (!textareaRef.current || !coverBoxRef.current || !value) return '';

        const textarea = textareaRef.current;
        const cursorPosition = textarea.selectionStart;

        // Get text before and after cursor position
        const beforeCursorText = value.slice(0, cursorPosition);
        const afterCursorText = value.slice(cursorPosition);

        // Split text in words
        const beforeWords = beforeCursorText.split(/\s+/);
        const afterWords = afterCursorText.split(/\s+/);

        // Get last word before cursor and first word after
        const lastWordBeforeCursor = beforeWords[beforeWords.length - 1] || '';
        const firstWordAfterCursor = afterWords[0] || '';

        // Join parts to get the word
        return `${lastWordBeforeCursor}${firstWordAfterCursor}`;
    }

    /**
     * Replace the new word in place of current word when user select a suggestion from suggestions list displayed.
     * @param newWord
     */
    const replaceCurrentWord = (newWord: string) => {
        const currentWordStart = getCurrentWordStart();
        const currentWordEnd = getCurrentWordEnd();

        if (isNil(currentWordStart) || isNil(currentWordEnd) || !textareaRef.current) return;

        const textarea = textareaRef.current;
        const text = textarea.value;

        const newText = text.slice(0, currentWordStart) + newWord + " " + text.slice(currentWordEnd);
        onTextChange(newText);

        setCurrentWord('');
        textarea.focus();
    }

    /**
     * Handle when user select a suggestion from suggestions list.
     * @param user
     */
    const handleSelectSuggestion = (user: ItemType) => {
        replaceCurrentWord(`@${user.username}`)
        setSuggestions([]);
        setShowSuggestions(false)
    };

    return (
        <div className={styles["textarea-box"]}>
            <textarea
                ref={textareaRef}
                data-testid={testId}
                placeholder={placeholder ?? ""}
                maxLength={maxLength}
                onChange={(e) => onTextChange(e.target.value)}
                onKeyUp={handleCursorChange}
                onMouseUp={handleCursorChange}
                value={value}
                className={className}
            />
            <div ref={coverBoxRef} className={styles["cover-box"]}></div>
            <div
                className={styles.suggestions}
                style={{
                    left: `${suggestionsCoordinates.left + 10}px`,
                    top: `${suggestionsCoordinates.top + 10}px`
                }}
            >
                <ul data-testid="suggestions-list" className={styles["suggestions-list"]}>
                    {loadingSuggestions && <li className={styles["not-clickable-item"]}>Loading...</li>}
                    {showSuggestions && isEmpty(suggestions) && <li className={styles["not-clickable-item"]}>No suggestions found</li>}
                    {showSuggestions && !isEmpty(suggestions) && suggestions.map((user, index) => (
                        <li className={styles["suggestions-item"]} key={index} onClick={() => handleSelectSuggestion(user)}>
                            {user.display}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
