/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import styles from "./CommentForm.module.scss"
import { ReactComponent as IconInfoCircle } from "assets/icons/info-circle.svg"
import Tooltip from '@mui/material/Tooltip';
import { VisibilityToggle } from "components/visibility-toggle/VisibilityToggle";
import { LoaderButton } from "components/loader-button/LoaderButton";
import classNames from "classnames";
import { VisibilityStatusEnum } from "appConstants";
import { MentionsTextarea } from "components/mentions-textarea/MentionsTextarea"

interface ICommentFormProps {
    maxLength?: number;
    placeholder?: string | Map<VisibilityStatusEnum, string>;
    onAccept: (text:string, visibility: VisibilityStatusEnum) => Promise<void>;
    onCancel?: () => void,
    onChange?: (text:string) => void,
    title?: string;
    className?: string;
    acceptBtnLabel?: string | Map<VisibilityStatusEnum, string>;
    disabled?: boolean;
}

export const CommentForm: React.FC<ICommentFormProps> = ({
    title = "Comment",
    maxLength = 5000,
    onAccept,
    onCancel,
    onChange,
    placeholder = "Write a comment...",
    className,
    acceptBtnLabel = "Accept",
    disabled = false,
}) => {
    const [visibility, setVisibility] = useState(VisibilityStatusEnum.INTERNAL);
    const [text, setText] = useState("");
    const tipsMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.INTERNAL, "Comments are only visible to Staff. To mark comments to be visible to a Homeowner, edit the ticket to mark it visible to Staff & Owner."],
        [VisibilityStatusEnum.OWNER, "Comments are visible to Homeowner. To mark comments to be only visible to Staff , edit the ticket to mark it visible to Staff Only."]
    ]);

    const acceptLabel: string = typeof acceptBtnLabel === "string" ? acceptBtnLabel : (acceptBtnLabel.get(visibility) || "");
    const placeholderLabel: string = typeof placeholder === "string" ? placeholder : (placeholder.get(visibility) || "");

    const onToggleUpdate = (value: VisibilityStatusEnum) => setVisibility(value);

    const onTextChange = (value: string) => {
        setText(value);
        if (onChange) onChange(value);
    };

    const onSubmit = async() => {
        await onAccept(text, visibility);
        setText("");
    };

    return (
        <div data-testid="comment-form" className={classNames(styles.content, className)}>
            <h2>{title}</h2>
            <div>
                <div className={styles["content-toggle"]}>
                    {/* Selector */}
                    <VisibilityToggle onUpdate={onToggleUpdate} defaultVisibility={VisibilityStatusEnum.INTERNAL} />
                    
                    {/* Info Badge */}
                    <Tooltip title={tipsMap.get(visibility)}>
                        <a className={styles.more} >
                            <span className={styles["info-icon"]}><IconInfoCircle /></span>
                            Learn more
                        </a>
                    </Tooltip>
                </div>
                
                {/* Input */}
                <div className={styles["content-bottom"]}>
                    <MentionsTextarea
                        placeholder={placeholderLabel}
                        maxLength={maxLength}
                        testId="comment-textarea"
                        onChange={onTextChange}
                        value={text}
                    />
                    <div className={classNames(styles.submit)}>
                        <span className={styles["word-count"]}>{text.length}/{maxLength}</span>
                        <span>
                            {onCancel && <LoaderButton id="comment-form-cancel-btn" onClick={() => onCancel()}>Cancel</LoaderButton>}
                            <LoaderButton id="comment-form-accept-btn" disabled={disabled} variant="primary-inverse" onClick={onSubmit}>{acceptLabel}</LoaderButton>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
