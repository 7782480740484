import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import { ReactComponent as RemoveWindow} from "assets/icons/remove-window.svg"
import { TicketEditHeader } from "components/ticket-edit-header/TicketEditHeader";
import { TicketEditSeverity } from "components/ticket-edit-severity/TicketEditSeverity";
import { TicketEditAssignment } from "components/ticket-edit-assignment/TicketEditAssignment";
import { TicketEditInfo } from "components/ticket-edit-info/TicketEditInfo";
import { TicketEditAttachments } from "components/ticket-edit-attachments/TicketEditAttachments";
import { TicketEditUnit } from "components/ticket-edit-unit/TicketEditUnit";
import { TicketEditDueDate } from "components/ticket-edit-due-date/TicketEditDueDate";
import { TicketEditDescription } from "components/ticket-edit-description/TicketEditDescription";
import { TicketEditComments } from "components/ticket-edit-comments/TicketEditComments";
import { TicketEditTimes } from "components/ticket-edit-times/TicketEditTimes";
import { TicketEditHistory } from "components/ticket-edit-history/TicketEditHistory";
import { TicketEditReminders } from "components/ticket-edit-reminders/TicketEditReminders";
import { TicketEditPassCodes } from "components/ticket-edit-passcodes/TicketEditPassCodes";
import { TicketEditFollowers } from "components/ticket-edit-followers/TicketEditFollowers";
import { useSelector, useDispatch} from "react-redux";
import { RootState } from "store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchTicket } from "store/ticketEditSlice";
import classNames from 'classnames';
import styles from "./TicketEdit.module.scss";
import { TicketEditSkeleton } from "components/ticket-edit-skeleton/TicketEditSkeleton";
import { ConnectService } from "services/ConnectService";
import { CurrentUserType, TicketV1Attributes } from "typing/dto";
import { Banner } from "components/banner/Banner";
import { env } from "core/env";
import { ActionBar } from "components/action-bar/ActionBar";
import { ActionLinkEnum } from "appConstants";
import useResizeObserver from "hooks/useResizeObserver";
import { Configuration } from "core/configuration";
import { isAdmin } from "utils/helpers";
import useURLParams from 'hooks/useURLParams';

export const TicketEdit: React.FC = () => {
    useResizeObserver(Configuration.innerRootId);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const {iframeParam, isBannerEnabled} = useURLParams();
    const {ticket_id: ticketIdParam} = useParams<{ticket_id: string}>();
    const ticketId = parseInt(ticketIdParam || "");
    const isEditable = useSelector((state: RootState) => state.ticketEdit.isEditable);
    const creator = useSelector((state: RootState) => state.ticketEdit.creator);
    const ticket = useSelector((state: RootState) => state.ticketEdit.ticket);
    const followers = useSelector((state: RootState) => state.ticketEditFollowers.followers);
    const unitTimezone = useSelector((state: RootState) => state.ticketEditUnit.unit?.timezone);
    const currentConnectUser = ConnectService.getInstance().currentUser;
    const owners = useSelector((state: RootState) => state.ticketEditHistory.owners);
    // Check if the owner of the unit is the creator of the ticket
    const isCreatedByOwner = owners.some(owner => owner && creator && owner?.id === creator?.id);

    const currentUser: CurrentUserType = {
        id: currentConnectUser?.id || 0,
        firstName: currentConnectUser?.first_name || "",
        lastName: currentConnectUser?.last_name || ""
    };

    // Action buttons that are allowed to be displayed in the action bar
    const allowedActionButtons = {
        [ActionLinkEnum.ADD_TICKET]: true,
        [ActionLinkEnum.SEARCH_TICKET]: true,
    };

    /**
     * Updates the document title based on the ticket information and notifies the parent frame.
     *
     * @param {TicketV1Attributes | null} ticket - The ticket containing title information. If null, the function does nothing.
     */
    const setAndEmitTitleToParent = (ticket: null | TicketV1Attributes) => {
        if (!ticket) return;
        document.title = `${ticket.title} | ${ticket.id} | Vacasa Tickets`;
        window.parent.postMessage(
            { type: 'iframeTitle', title: document.title },
            '*'
        );
    };

    useEffect(() => {
        setAndEmitTitleToParent(ticket);
    }, [ticket]);

    useEffect(() => {
        dispatch(fetchTicket(ticketId));
    }, [dispatch, ticketId]);
    
    const bannerComponent = <Banner 
        header={"Not ready for the new view?"}
        text={"Switch back to the classic interface easily!"}
        button={{text: "Switch Now", link: `${env.REACT_APP_ADMIN_URL}/admin/dashboard/tickets/goBackToOldUI?TicketID=${ticketId}&${iframeParam}`}}
        icon={<RemoveWindow/>}
    />

    // Display loader until
    if (!ticket) {
        return(
            <>
                { isBannerEnabled &&
                    <div className={classNames(styles.container,"max-width")}>
                        {bannerComponent}
                    </div>
                }
                <TicketEditSkeleton />
            </>
        )
    }

    return (
        <div className={classNames(styles.container,"max-width")}>
            {/* Action Bar */}
            { isAdmin() && <ActionBar unitId={String(ticket.unit_id)} allowedButtons={allowedActionButtons}/> }
            {/* Banner */}
            { isBannerEnabled && bannerComponent}
            <TicketEditHeader ticket={ticket}/>
            <div className={styles.subheader}>
                <TicketEditAssignment userId={ticket.assigned_to} ticketId={ticketId} isEditable={isEditable} />
                <TicketEditSeverity ticketId={ticketId} isEditable={isEditable} />
                <TicketEditDueDate ticketId={ticketId} isEditable={isEditable}/>
            </div>
            <div className={styles.content}>
                <div className={styles["content-left"]}>
                    <TicketEditDescription ticketId={ticketId}/>
                    <TicketEditComments ticketId={ticketId}/>
                    <TicketEditHistory ticketId={ticketId} unitId={ticket.unit_id}/>
                </div>
                <div className={styles["content-right"]}>
                    <TicketEditReminders ticketId={ticketId} isEditable={isEditable} />
                    <TicketEditPassCodes userId={currentUser.id} ticketId={ticketId} unitId={ticket.unit_id} unitTimezone={unitTimezone} />
                    <TicketEditInfo ticketAttr={ticket}/>
                    <TicketEditUnit unitId={ticket.unit_id} />
                    <TicketEditAttachments ticketId={ticketId}/>
                    <TicketEditTimes
                        ticketId={ticketId}
                        started={ticket.start_date}
                        completed={ticket.completed_date}
                        void={ticket.void}
                        due={ticket.completion_date}
                        created={ticket.creation_date}
                        creator={creator}
                        isCreatedByOwner={isCreatedByOwner}
                    />
                    <TicketEditFollowers ticketId={ticketId} followers={followers} currentUser={currentUser}/>
                </div>
            </div>
        </div>
    );
}
