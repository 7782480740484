import {ImplicitFlowService} from "./ImplicitFlowService";
import {env} from "core/env";
import {HandleServiceError} from "decorators/service";
import qs from "qs";
import {UnitV2Attributes} from "typing/dto";
import type {CustomRequestConfiguration, DataType, QueryParams, UnitServiceFilter} from "typing/request";

export class UnitService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_UNITS_SERVICE_URL;
    private static service: UnitService;

    constructor() {
        super(UnitService.baseUrl);
    }

    /**
     * Get's Singleton Instance
     */
    public static getInstance(): UnitService {
        if (!UnitService.service) {
            UnitService.service = new UnitService();
        }
        return UnitService.service;
    }

    /**
     * Get Unit Entity based on it's Id
     */
    @HandleServiceError()
    public async getUnitByLegacyId(legacyUnitId: number): Promise<UnitV2Attributes | null> {
        const axios = await this.axiosInstance();
        const params = {
            "filter[legacy_unit_id]": legacyUnitId,
        };
        const {data: response} = await axios.get(`${this.apiUrl}/units`, {params});

        // early return if none
        if (!response?.data[0]) return null;

        // append id to attributes for easier handling
        response.data[0].attributes.id = response.data[0].id;
        return response?.data[0];
    }

    /**
     * Gets Unit entities based on legacy id
     */
    public async getUnitsByLegacyId(legacyIds: string): Promise<UnitV2Attributes[]> {
        const result = await this.getUnitsByParams({
            filter: {
                legacy_unit_id: {
                    in: legacyIds,
                },
            },
        });
        return result ?? [];
    }

    /**
     * Gets any entity based on units service filters and pagination
     * @param params
     * @param customRequestConfig CustomRequestConfiguration
     * @returns
     */
    @HandleServiceError()
    public async getUnitsByParams(
        params: QueryParams<UnitServiceFilter>,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<UnitV2Attributes[]> {
        const axios = await this.axiosInstance(customRequestConfig);
        const {data: response} = await axios.get(`${this.apiUrl}/units`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: "brackets"});
            },
        });
        return response.data.map((unit: DataType<UnitV2Attributes>) => ({
            ...unit.attributes,
            id: unit.id,
        }));
    }
}
