import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA, TicketInclude, TicketStatusEnum} from "appConstants";
import isNil from "lodash/isNil";
import {ConnectService} from "services/ConnectService";
import {TicketingService} from "services/TicketingService";
import {
    TicketFollowerV1,
    TicketFollowerV1Attributes,
    TicketSalesforceParentV1,
    TicketSalesforceParentV1Attributes,
    TicketV1Attributes,
    UserConnectAttributes,
} from "typing/dto";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_DUE_DATE_UPDATE, TICKET_DETAILS_UPDATE, TICKET_DESCRIPTION_UPDATE, TICKET_SEVERITY_UPDATE, TICKET_LOAD} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();

export interface IFetchTicketThunkResponse {
    ticket: TicketV1Attributes;
    creator: UserConnectAttributes | null;
    followers: TicketFollowerV1Attributes[];
    salesforceParent: TicketSalesforceParentV1Attributes[];
}

/**
 * Thunk that fetches Ticket and Creator
 */
export const fetchTicket = createAsyncThunk("ticketEdit/fetchTicket", async (ticketId: number): Promise<IFetchTicketThunkResponse> => {
    const ticketResponse = await ticketingService.getTicketById(ticketId, [TicketInclude.FOLLOWERS, TicketInclude.SALESFORCE_PARENT]);
    const userResponse = await connectService.getSilentUserById(ticketResponse.data.attributes.created_by);
    const followers: TicketFollowerV1[] | undefined = ticketResponse?.data.includedMap?.followers;
    const salesforceParent: TicketSalesforceParentV1[] | undefined = ticketResponse?.data.includedMap?.sf_parent;
    return {
        ticket: ticketResponse.data.attributes,
        creator: userResponse?.data.attributes as UserConnectAttributes,
        followers: isNil(followers) ? [] : followers.map((follower: TicketFollowerV1) => follower.attributes),
        salesforceParent: isNil(salesforceParent)
            ? []
            : salesforceParent.map((salesforceParent: TicketSalesforceParentV1) => salesforceParent.attributes),
    };
});

export const updateSeverity = createAsyncThunk<TicketV1Attributes, {ticketId: number; severityId: string | number}, {rejectValue: string}>(
    "ticketEdit/updateSeverity",
    async ({ticketId, severityId}: any, {rejectWithValue}) => {
        try {
            return ticketingService.patchTicket(ticketId, {severity: severityId});
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export const updateDueDate = createAsyncThunk(
    "ticketEditTime/updateDueDate",
    async ({ticketId, newDueDate}: {ticketId: number; oldDueDate: string | null | undefined; newDueDate: string}, {rejectWithValue}) => {
        try {
            const ticketUpdated: TicketV1Attributes = await ticketingService.patchTicket(ticketId, {completion_date: newDueDate});
            return ticketUpdated.completion_date;
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

interface IUpdateDescriptionRequest {
    ticketId: number;
    description: string;
}
export const updateDescription = createAsyncThunk(
    "ticketEdit/updateDescription",
    async ({ticketId, description}: IUpdateDescriptionRequest, {rejectWithValue}): Promise<TicketV1Attributes> => {
        try {
            return ticketingService.patchTicket(ticketId, {description});
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

interface IUpdateDetailsRequest {
    ticketId: number;
    details: string;
}
export const updateDetails = createAsyncThunk(
    "ticketEdit/updateDetails",
    async ({ticketId, details}: IUpdateDetailsRequest, {rejectWithValue}): Promise<TicketV1Attributes> => {
        try {
            return ticketingService.patchTicket(ticketId, {template_body: details});
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export type TicketStatus = `${TicketStatusEnum}`;

export interface ITicketEditState {
    creator: null | UserConnectAttributes;
    isCanceled: boolean;
    isCompleted: boolean;
    isEditable: boolean;
    isCurrentUserAssigned: boolean;
    isLoading: boolean;
    isOpen: boolean;
    isSilentLoading: boolean;
    isStarted: boolean;
    ticket: null | TicketV1Attributes;
    ticketStatus: TicketStatus;
}

const setTicketStatusByTicket = (ticket: TicketV1Attributes): TicketStatus => {
    const {completed_date, void: void_attr, start_date} = ticket;
    let result: TicketStatus = TicketStatusEnum.OPEN;
    if (!!void_attr) result = TicketStatusEnum.CANCELED;
    else if (!!completed_date) result = TicketStatusEnum.COMPLETED;
    else if (!!start_date) result = TicketStatusEnum.STARTED;
    return result;
};

const ticketEditSlice = createSlice({
    name: "ticketEdit",
    initialState: {
        creator: null,
        isCanceled: false,
        isCompleted: false,
        isCurrentUserAssigned: false,
        isLoading: false,
        isOpen: true,
        isSilentLoading: false,
        isStarted: false,
        ticket: null,
        ticketStatus: "open",
    } as ITicketEditState,
    reducers: {
        silentLoading(state: ITicketEditState) {
            state.isSilentLoading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTicket.pending, (state: ITicketEditState) => {
                if (state.isSilentLoading) return;
                state.isLoading = true;
                state.ticket = null;
            })
            .addCase(fetchTicket.fulfilled, (state: ITicketEditState, {payload}: {payload: IFetchTicketThunkResponse}) => {
                const ticketStatus = setTicketStatusByTicket(payload.ticket);
                state.isLoading = false;
                state.ticket = payload.ticket as TicketV1Attributes;
                state.creator = payload.creator as UserConnectAttributes;
                state.isOpen = ticketStatus === TicketStatusEnum.OPEN;
                state.isStarted = ticketStatus === TicketStatusEnum.STARTED;
                state.isCompleted = ticketStatus === TicketStatusEnum.COMPLETED;
                state.isCanceled = ticketStatus === TicketStatusEnum.CANCELED;
                state.isEditable = ticketStatus === TicketStatusEnum.OPEN || ticketStatus === TicketStatusEnum.STARTED;
                state.ticketStatus = ticketStatus;
                state.isSilentLoading = false;
                state.isCurrentUserAssigned = connectService.currentUserId === payload.ticket.assigned_to;
            })
            .addCase(fetchTicket.rejected, (_state: ITicketEditState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_LOAD, error: Error(payload)});
            })
            .addCase(updateSeverity.rejected, (_state: ITicketEditState, {payload}) => {
                enqueueError({logInfo: TICKET_SEVERITY_UPDATE, error: Error(payload)});
            })
            .addCase(updateSeverity.fulfilled, (state: ITicketEditState, {payload}: {payload: TicketV1Attributes}) => {
                if (!state.ticket) return;
                state.ticket.severity = payload.severity;
                enqueueSuccess({logInfo: TICKET_SEVERITY_UPDATE, data: {severity: payload.severity}});
            })
            .addCase(updateDescription.rejected, (_state: ITicketEditState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_DESCRIPTION_UPDATE, error: Error(payload)});
            })
            .addCase(updateDescription.fulfilled, (state: ITicketEditState, {payload}: {payload: TicketV1Attributes}) => {
                if (!state.ticket) return;
                const {description} = payload;
                state.ticket.description = description;
                enqueueSuccess({logInfo: TICKET_DESCRIPTION_UPDATE, data: {description}});
            })
            .addCase(updateDetails.rejected, (_state: ITicketEditState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_DETAILS_UPDATE, error: Error(payload)});
            })
            .addCase(updateDetails.fulfilled, (state: ITicketEditState, {payload}: {payload: TicketV1Attributes}) => {
                if (!state.ticket) return;
                const {template_body} = payload;
                state.ticket.template_body = template_body;
                enqueueSuccess({logInfo: TICKET_DETAILS_UPDATE, data: {template_body}});
            })
            .addCase(updateDueDate.fulfilled, (state: ITicketEditState, {payload}: {payload: string}): void => {
                if (!state.ticket) return;
                state.ticket.completion_date = payload;
                enqueueSuccess({logInfo: TICKET_DUE_DATE_UPDATE, data: {completion_date: payload}});
            })
            .addCase(updateDueDate.rejected, (_state: ITicketEditState, {payload}: {payload: any}): void => {
                enqueueError({logInfo: TICKET_DUE_DATE_UPDATE, error: Error(payload)});
            });
    },
});

export const {silentLoading} = ticketEditSlice.actions;

export default ticketEditSlice.reducer;
