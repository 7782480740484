import {ImplicitFlowService} from "./ImplicitFlowService";
import {env} from "core/env";
import {HandleServiceError} from "decorators/service";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import {ReservationAttributes} from "typing/dto";
import {DataType, ReservationsFilter} from "typing/request";

export class ReservationService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_RESERVATION_SERVICE_URL;
    private static service: ReservationService;

    constructor() {
        super(ReservationService.baseUrl);
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): ReservationService {
        if (!ReservationService.service) {
            ReservationService.service = new ReservationService();
        }
        return ReservationService.service;
    }

    /**
     * Get Device Gateways list
     * @param reservationId
     */
    @HandleServiceError()
    public async getReservationByLegacyID(reservationId: number): Promise<DataType<ReservationAttributes> | null> {
        const axios = await this.axiosInstance();
        const filter: ReservationsFilter = {
            legacy_reservation_id: reservationId,
        };
        const {data: response} = await axios.get(`${this.apiUrl}/v1/reservations`, {
            params: {filter},
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: "brackets"});
            },
        });
        if (isEmpty(response?.data[0])) return null;

        return response.data[0];
    }
}
