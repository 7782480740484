export default class RouteGenerator {
    /**
     * Generates a search route URL with query parameters formatted as filters.
     *
     * @param {Record<string, string>} params - An object where keys are parameter names and values are their corresponding values.
     * @returns {string} - The generated URL path with query parameters.
     */
    static search(params: Record<string, string>): string {
        const paramsWithFilter: Record<string, string> = {};

        for (const [key, value] of Object.entries(params)) {
            paramsWithFilter[`filter[${key}]`] = value;
        }

        const url = new URL("/ticket/search", window.location.href);
        url.search = new URLSearchParams(paramsWithFilter).toString();
        return url.pathname + url.search;
    }
}
